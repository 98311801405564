import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/PostList';
import { KnowledgeHubHeader } from '../components/KnowledgeHubHeader';
import './blog.scss';

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    const { data: { postsOnPage: { edges: posts } } } = this.props;
    this.state = {
      activePosts: posts
    };
  }

  render() {
    const { data, pageContext, location } = this.props;
    const { activePosts } = this.state;
    const { site, categories } = data;
    const { title: siteTitle, blogSlug } = site.siteMetadata;
    const pathPrefix = `/${blogSlug}`;
    const allCategoriesNoDummyPost = categories.edges.filter(({node: category}) => {
      if (category.slug === 'uncategorised' && category.count === 1) return false;
      return true;
    });
    return (
      <Layout className="blog-page-template">
        <SEO title={`Journals | ${siteTitle}`} />
        <KnowledgeHubHeader
          activeTab="blog"
          location={location}
        />
        <div className="wrap">
          <PostList
            title="Articles"
            posts={activePosts}
            pageContext={pageContext}
            pathPrefix={pathPrefix}
            categories={allCategoriesNoDummyPost}
          />
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        blogSlug
      }
    }
    allPosts: allWordpressPost(filter: { slug: { ne: "dummy-post" } }) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    postsOnPage: allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "dummy-post" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
